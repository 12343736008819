<template>
    <div>
        <div class="col-lg-12 text-2">
            <h4 class="text-center">Cancellation Policy</h4>

            <p>Last updated February 21, 2024</p>

            <p class="pt-2">Thank you for using our app/website. This Cancellation Policy outlines the terms and
                conditions regarding the cancellation of purchases made through our app.</p>
            <ol class="pl-5">
                <li class="font-weight-500">
                    No Cancellation:
                </li>
                <ol class="pl-4 pt-1" style="list-style-type:lower-alpha ">
                    <li>
                        All purchases made for access to content through our app/website are non-cancellable.
                    </li>
                    <li>Once a purchase is made, you acknowledge and agree that it cannot be cancelled, refunded, or
                        reversed.
                    </li>
                </ol>
                <li class="font-weight-500 pt-2">Access Until Expiry:</li>
                <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">

                    <li> Purchases made for access to content provide access until the expiration of the specified
                        period (monthly, 3-months, half yearly or annually).
                    </li>
                    <li> You will continue to have access to the purchased content until the end of the current purchase
                        period, after which you will need to repurchase the content to regain access.
                    </li>
                </ol>
                <li class="font-weight-500 pt-2">Repurchase Required:</li>
                <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                    <li>
                        Upon expiration of the access period (monthly, 3-months, half yearly or annually), you will need
                        to repurchase the content to continue accessing it.
                    </li>
                    <li>There is no automatic renewal or subscription model associated with the purchase of content.
                        Each purchase provides access for a specific period, after which a new purchase is required to
                        regain access.
                    </li>
                </ol>

                <li class="font-weight-500 pt-2">No Refunds:</li>
                <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                    <li>
                        Since purchases are non-cancellable, refunds will not be provided for any reason.
                    </li>

                </ol>
                <li class="font-weight-500 pt-2">Billing Disputes:</li>
                <ol class="pl-4 pt-1" style="list-style-type:lower-alpha">
                    <li>
                        If you believe that a purchase has been made in error or without your authorization, please
                        contact our customer support team immediately to resolve the issue.
                    </li>
                    <li>We reserve the right to investigate and take appropriate action, including suspending or
                        terminating access to content, in cases of suspected fraudulent or unauthorized purchases.
                    </li>
                </ol>
                <li class="font-weight-500 pt-2">Contact Us:</li>

                <p>If you have any questions or concerns about our refund policy, please contact our customer
                    support team
                    at
                    foveatechvet@gmail.com</p>

                <li class="font-weight-500 "> Agreement to Refund Policy:</li>
                <p>By making a payment for access to premium features or content through our app, you acknowledge and
                    agree
                    to
                    abide by the terms and conditions of this refund policy.</p>
            </ol>
        </div>

    </div>
</template>

<script>
export default {
    name: 'CancellationPolicy'
};
</script>

<style scoped>

</style>
